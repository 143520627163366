import { ProgressCircle } from '@daangn/carotene';
import { PropsWithChildren, Suspense } from 'react';

export const SearchResultLoading = () => (
  <div className="py-20 flex justify-center">
    <ProgressCircle />
  </div>
);

export const SearchResultDropdown = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <div className="relative">
      <div className="absolute top-0 left-0 right-0 overflow-auto z-10 max-h-60 py-2 rounded-md bg-bg-neutral flex justify-center items-stretch">
        {children}
      </div>
    </div>
  );
};

export const SearchResultWarning = ({ children }: PropsWithChildren<unknown>) => (
  <div className="py-20 text-center text-fg-neutralSubtle body-small-default">{children}</div>
);

type Props = {
  input: string;
  query: string;
  minInputLength: number;
  children: React.ReactNode;
};

export const SearchResultRenderer = ({ input, query, minInputLength, children }: Props) => {
  return (
    <SearchResultDropdown>
      <Suspense fallback={<SearchResultLoading />}>
        {input.length < minInputLength ? (
          <SearchResultWarning>{minInputLength}글자 이상 입력해주세요</SearchResultWarning>
        ) : input !== query ? (
          <SearchResultLoading />
        ) : (
          children
        )}
      </Suspense>
    </SearchResultDropdown>
  );
};
