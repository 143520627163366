import { useArticleForm } from '@/routes/_protected/articles/-contexts';
import { ArticleOptionNameEnum, ArticleOptionValueEnum } from '@/types/schemaEnums';
import { getArticleOptionNameText } from '@daangn/realty-sdk';
import { useController } from 'react-hook-form';
import { omit, unionBy } from 'lodash-es';
import { RequiredArticleOptionNames } from '@/constants/article';
import FieldLogger from '@/routes/_protected/articles/-components/FieldLogger';
import { Checkbox } from '@daangn/carotene';

const EtcArticleOptionNames = Object.values(ArticleOptionNameEnum).filter(
  (name) => !RequiredArticleOptionNames.includes(name)
);

const OptionField = () => {
  const { control, watch } = useArticleForm();
  const { field } = useController({ control, name: 'options' });

  const handleChangeOptionValue = (name: ArticleOptionNameEnum, value: ArticleOptionValueEnum) => {
    field.onChange(unionBy([{ name, value }], watch('options') ?? [], 'name'));
  };

  return (
    <div className="flex flex-wrap gap-y-8 gap-x-6">
      {EtcArticleOptionNames.map((name) => {
        const option = field.value?.find((option) => option.name === name);

        return (
          <div key={name} className="min-w-[140px]">
            <FieldLogger name="option" eventParams={{ type: name }}>
              <Checkbox
                {...omit(field, 'value', 'onChange')}
                checked={option?.value === ArticleOptionValueEnum.Yes}
                onCheckedChange={(isChecked) =>
                  handleChangeOptionValue(
                    name,
                    isChecked ? ArticleOptionValueEnum.Yes : ArticleOptionValueEnum.No
                  )
                }
                label={getArticleOptionNameText(name, { isBroker: true })}
              >
                {getArticleOptionNameText(name, { isBroker: true })}
              </Checkbox>
            </FieldLogger>
          </div>
        );
      })}
    </div>
  );
};

export default OptionField;
