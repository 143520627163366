/**
 * @generated SignedSource<<879e3caf2577af2ee530f591dd64f197>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type SalesTypeEnum = "APART" | "ETC" | "OFFICETEL" | "OPEN_ONE_ROOM" | "SPLIT_ONE_ROOM" | "STORE" | "TWO_ROOM";
import { FragmentRefs } from "relay-runtime";
export type articleSummaryInfo_article$data = {
  readonly addressInfo: string;
  readonly area: string | null;
  readonly floor: string | null;
  readonly salesTypeV2: {
    readonly type: SalesTypeEnum;
  };
  readonly supplyArea: string | null;
  readonly topFloor: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"articleCostText_article" | "articleSalesTypeText_article">;
  readonly " $fragmentType": "articleSummaryInfo_article";
};
export type articleSummaryInfo_article$key = {
  readonly " $data"?: articleSummaryInfo_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"articleSummaryInfo_article">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "articleSummaryInfo_article"
};

(node as any).hash = "3589223dc1d5a35b13195febf2fac283";

export default node;
