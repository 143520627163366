import { forwardRef } from 'react';
import { ProgressCircle } from '@daangn/carotene';

const BlockStyleLoader = forwardRef<HTMLDivElement>(({ ...props }, ref) => {
  return (
    <div
      className="flex items-center justify-center pt[10px] pb[20px] empty:p-0"
      ref={ref}
      {...props}
    >
      <ProgressCircle />
    </div>
  );
});

export default BlockStyleLoader;
