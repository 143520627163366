/**
 * @generated SignedSource<<946976e82ca303a7190783c061b6692b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ArticleInvisibleStatusEnum = "BLOCKED" | "CHECK_DENY" | "NONE";
export type ArticleStatusEnum = "ON_GOING" | "RESERVED" | "TRADED";
export type TradeTypeEnum = "BORROW" | "BUY" | "MONTH" | "SHORT" | "YEAR";
import { FragmentRefs } from "relay-runtime";
export type ArticleTitle_article$data = {
  readonly invisibleStatus: ArticleInvisibleStatusEnum;
  readonly isHide: boolean;
  readonly originalId: string;
  readonly status: ArticleStatusEnum;
  readonly trades: ReadonlyArray<{
    readonly deposit?: number;
    readonly description?: string | null;
    readonly monthlyPay?: number;
    readonly preferred: boolean;
    readonly price?: number;
    readonly type: TradeTypeEnum;
    readonly yearlyPay?: number;
  }>;
  readonly " $fragmentType": "ArticleTitle_article";
};
export type ArticleTitle_article$key = {
  readonly " $data"?: ArticleTitle_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleTitle_article">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deposit",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "monthlyPay",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleTitle_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "originalId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invisibleStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isHide",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "trades",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "preferred",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "ShortTrade",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "MonthTrade",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "yearlyPay",
              "storageKey": null
            }
          ],
          "type": "YearTrade",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "type": "BorrowTrade",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "price",
              "storageKey": null
            }
          ],
          "type": "BuyTrade",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "79f21ee6499aa69afaa8d974ee88c499";

export default node;
