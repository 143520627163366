import {
  FormProvider,
  Message,
  useForm,
  type DefaultValues,
  type UseFormReturn,
} from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ArticleFormInputType, articleFormScheme, type ArticleFormType } from '@/scheme/article';
import SalesTypeField from './SalesTypeField';
import TradeTypeField from './TradeTypeField';
import AddressInfoField from './AddressInfoField';
import PriceField from './PriceField';
import OrientationField from './OrientationField';
import AreaField from './AreaField';
import RoomFloorField from './RoomFloorField';
import ManageCostField from './ManageCostField';
import RequiredOptionField from './RequiredOptionField';
import IncludeManageCostOptionField from './IncludeManageCostOptionField';
import OptionField from './OptionField';
import QualitativeField from './QualitativeField';
import ContentField from './ContentField';
import AddressField from './AddressField';
import ImageField from './ImageField';
import VideoField from './VideoField';
import MoveInDateField from './MoveInDateField';
import BuildingApprovalDate from './BuildingApprovalDateField';
import Divider from './Divider';
import Section from './Section';
import BrokerInfo from './BrokerInfo';
import { Button } from '@daangn/carotene';
import BuildingUsageField from '@/routes/_protected/articles/-components/BuildingUsageField';
import ContactTargetNumberField from '@/routes/_protected/articles/-components/ContactTargetNumberField';
import { useImperativeHandle, useRef, useState, forwardRef, Suspense, lazy } from 'react';
import AiConversionDialog from '@/routes/_protected/articles/-components/AiConversionDialog';
import type { z } from 'zod';
import logger from '@/utils/Logger';
import { getElementOffsetTop } from '@/utils/dom';
import { STAGE } from '@/config';

export type ArticleFormRef = UseFormReturn<ArticleFormInputType>;

type ArticleFormProps = {
  isEdit?: boolean;
  isLoading?: boolean;
  onSubmit: (data: ArticleFormType) => void;
  initialValues?: DefaultValues<ArticleFormInputType>;
};

export type ArticleFormFieldError = {
  [K in keyof ArticleFormInputType]?: {
    type: string;
    message?: Message;
  };
};

const ArticleForm = forwardRef<ArticleFormRef, ArticleFormProps>(
  ({ onSubmit, initialValues, isEdit, isLoading }, ref) => {
    const containerRef = useRef<HTMLFormElement>(null);
    const [isAIConversionComplete, setIsAIConversionComplete] = useState(false);
    const form = useForm<ArticleFormInputType>({
      resolver: zodResolver(articleFormScheme),
      defaultValues: initialValues,
    });

    useImperativeHandle(ref, () => form);

    const handleAIConversionComplete = () => {
      setIsAIConversionComplete(true);
    };

    const handleClickSubmit = async () => {
      logger.track('click_submit', {});

      form.handleSubmit(
        async (values: unknown) => {
          const form = values as z.infer<typeof articleFormScheme>;

          onSubmit(form);
        },
        (errors) => {
          const param = Object.fromEntries(
            Object.entries(errors).map(([name, { message, type }]) => [name, { message, type }])
          );

          logger.track('failed_submit', {
            errors: param as ArticleFormFieldError,
          });

          const firstError = Object.entries(errors).sort((a, b) => {
            const aElement = containerRef.current?.querySelector<HTMLElement>(`[name=${a[0]}]`);
            const bElement = containerRef.current?.querySelector<HTMLElement>(`[name=${b[0]}]`);

            if (aElement && bElement) {
              return getElementOffsetTop(aElement) - getElementOffsetTop(bElement);
            }
            return 0;
          })[0];

          if (firstError) {
            const [name] = firstError;
            const element = containerRef.current?.querySelector<HTMLElement>(`[name=${name}]`);
            element?.focus();
          }
        }
      )();
    };

    return (
      <div>
        <FormProvider {...form}>
          <form ref={containerRef} className="flex flex-col gap-10" onSubmit={handleClickSubmit}>
            <div className="flex flex-col gap-12">
              <Section title="중개사 정보">
                <BrokerInfo />
              </Section>
              <Divider />
              {!isEdit}
              <Section
                title="기본 정보"
                action={
                  !isEdit && !isAIConversionComplete ? (
                    <AiConversionDialog onConversionComplete={handleAIConversionComplete} />
                  ) : null
                }
              >
                <SalesTypeField />
                <TradeTypeField />
              </Section>
              <Divider />
              <Section title="매물 소재지/면적">
                <div className="grid grid-cols-2 gap-6">
                  <AddressField disabled={isEdit} />
                </div>
                <AreaField />
              </Section>
              <Divider />
              <Section title="매물 정보">
                <BuildingApprovalDate />
                <RoomFloorField />
                <OrientationField />
                <BuildingUsageField />
                <div className="grid grid-cols-2 gap-6">
                  <RequiredOptionField />
                </div>
              </Section>
              <Divider />
              <PriceField />
              <Divider />
              <Section title="관리비">
                <ManageCostField />
                <div className="grid grid-cols-2 gap-6">
                  <IncludeManageCostOptionField />
                </div>
              </Section>
              <Divider />
              <Section title="내외부 시설">
                <OptionField />
              </Section>
              <Divider />
              <Section title="매물 상세 정보">
                <div className="flex gap-6 justify-between">
                  <MoveInDateField />
                  <AddressInfoField />
                </div>
                <QualitativeField />
                <ContentField />
                <ImageField />
                <VideoField />
                <Divider />
                <ContactTargetNumberField />
              </Section>
            </div>
          </form>
          <Suspense fallback={null}>
            <DevFormHelper />
          </Suspense>
        </FormProvider>

        <div className="fixed bottom-0 left-0 right-0 border-t border-stroke-decorative bg-bg-layerDefault">
          <div className="flex items-center h-24 px-2 md:px-0 py-6 max-w-screen-md mx-auto">
            <p className="body-xsmall-default text-fg-placeholder">
              매물 등록 시에 공인중개사법 및 하위 법령, 중개대상물의 표시·광고 명시사항 세부기준
              등을 준수해야 하며,
              <br />
              이를 준수하지 않아 발생하는 불이익에 대해 당사는 책임지지 않습니다.
            </p>
            <div className="spacer" />
            <Button type="submit" size="xlarge" onClick={handleClickSubmit} disabled={isLoading}>
              {isEdit ? '매물 수정' : '매물 등록'}
            </Button>
          </div>
        </div>
      </div>
    );
  }
);

export default ArticleForm;

const DevFormHelper =
  STAGE === 'production'
    ? () => null
    : lazy(() => import('@/routes/_protected/articles/-components/DevFormHelper'));
