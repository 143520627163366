import { config, STAGE } from '@/config';
import { IconXmarkLine } from '@daangn/react-monochrome-icon';
import { createMinikarrotScheme } from '@daangn/webview-link-router';
import { memo, useEffect } from 'react';
import { QRCode } from 'react-qrcode-logo';
import { Dialog } from '../Dialog';
import { VisuallyHidden } from '@daangn/carotene';
import logger from '@/utils/Logger';

type Props = {
  articleId: string;
  isOpen: boolean;
  close: () => void;
};

const CarrotQRDialog = ({ articleId, isOpen, close }: Props) => {
  useEffect(() => {
    logger.track('show_article_qr_dialog', { article_id: articleId });
  }, []);

  const appScheme = createMinikarrotScheme({
    url: `${config.webviewUrl}/articles/${articleId}?referrer=realty-ceo`,
    stage: STAGE,
  });

  const handleOnOpenChange = (open: boolean) => {
    if (!open) close();
  };

  return (
    <Dialog.Root open={isOpen} onOpenChange={handleOnOpenChange}>
      <Dialog.Overlay />

      <Dialog.Content className="w-[560px] pb-10 !px-0 !pt-0">
        <VisuallyHidden>
          <Dialog.Title />
          <Dialog.Description />
        </VisuallyHidden>

        <div className="px-6 py-5 flex justify-between">
          <div className="heading-medium">당근 앱으로 열기</div>

          <Dialog.Close>
            <IconXmarkLine width={24} height={24} />
          </Dialog.Close>
        </div>

        <div className="w-full bg-gray100 py-[41px] flex items-center justify-center">
          <QRCode
            value={appScheme}
            size={160}
            logoImage="https://assetstorage.krrt.io/1138369647032855545/ba118f7e-06c5-4d72-b10d-9a64641e7ae5/width=65.7,height=112.3.webp"
            logoWidth={24}
            logoHeight={40}
            quietZone={9}
          />
        </div>

        <div className="body-medium-default text-fg-neutral text-center mt-4">
          모바일에서 이용할 수 있어요. <br />
          휴대전화 카메라나 당근 앱으로 QR코드를 촬영해 주세요.
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default memo(CarrotQRDialog);
