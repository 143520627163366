/* eslint-disable @typescript-eslint/ban-types */
import { EditArticleInputV3 } from '@/__generated__/editArticleMutation.graphql';
import { CreateArticleInputV3 } from '@/__generated__/newPageMutation.graphql';
import { ArticleFormFieldError } from '@/routes/_protected/articles/-components/ArticleForm';
import { type SalesHistoryStatus } from '@/routes/_protected/sales/-components/ChipList';

export type LoggerEventType = keyof LoggerEventSchema;
export type LoggerEventParams<T extends LoggerEventType> = LoggerEventSchema[T];

type EventType = 'click' | 'impression';
type EventProviderType = 'firebase' | 'ga4' | 'amplitude' | 'mixpanel';

export const DefaultEventProvider: Record<EventType, EventProviderType[]> = {
  click: ['amplitude'],
  impression: ['amplitude'],
};

/**
 * EventSchema - 이벤트별 타입을 정의
 */
export type LoggerEventSchema = {
  header_sales_my: {};
  header_sales_biz: {};
  click_article_item: { article_id: string };
  click_article_action_trade: { article_id: string };
  click_article_action_modify: { article_id: string };
  click_sales_history_tab: { status: SalesHistoryStatus; path: string };
  show_article_qr_dialog: { article_id: string };
  click_gnb_chat: {};
  header_logo: {};
  header_new: {};
  complete_edit_article: { article_id: string; input: CreateArticleInputV3 | EditArticleInputV3 };
  click_submit: {};
  failed_submit: { errors: ArticleFormFieldError };
  create_article: {};
  complete_submit: {
    input: CreateArticleInputV3 | EditArticleInputV3;
  };
  click_write_more: {};
  click_request_verify: {};
  confirm_continue_writing: {};
};

/**
 * EventProperties - 이벤트별 값을 정의e
 */
export const LoggerEventProperties: {
  [K in keyof LoggerEventSchema]: {
    description: string;
    type: EventType;
  };
} = {
  header_sales_biz: {
    description: '헤더 > 중개소 매물 클릭',
    type: 'click',
  },
  header_sales_my: {
    description: '헤더 > 나의 매물 클릭',
    type: 'click',
  },
  click_article_item: {
    description: '아티클 아이템 클릭',
    type: 'click',
  },
  click_article_action_trade: {
    description: '아티클 아이템 액션 > 거래 완료 버튼 클릭',
    type: 'click',
  },
  click_article_action_modify: {
    description: '아티클 아이템 액션 > 수정 버튼 클릭',
    type: 'click',
  },
  click_sales_history_tab: {
    description: '판매 상태 칩 > 칩 클릭',
    type: 'click',
  },
  show_article_qr_dialog: {
    description: '당근 앱으로 열기 다이얼로그 노출',
    type: 'impression',
  },
  click_gnb_chat: {
    description: '헤더 > PC에서 채팅하기 버튼 클릭',
    type: 'click',
  },
  header_logo: {
    description: '헤더 > 당근 로고 클릭',
    type: 'click',
  },
  header_new: {
    description: '헤더 > 매물 등록 클릭',
    type: 'click',
  },
  complete_edit_article: {
    description: '매물 수정 > 매물 수정 성공',
    type: 'click',
  },
  click_submit: {
    description: '매물 폼 > 등록/수정 버튼 클릭',
    type: 'click',
  },
  failed_submit: { description: '매물 폼 > validation 실패', type: 'click' },
  create_article: {
    description: '매물 등록 > 매물 등록 제출',
    type: 'click',
  },
  complete_submit: {
    description: '매물 등록 > 매물 등록 성공',
    type: 'click',
  },
  click_write_more: {
    description: '매물 등록 > 완료 > 매물 추가 등록하기 버튼 클릭',
    type: 'click',
  },
  click_request_verify: {
    description: '매물 등록 > 완료 >  집주인 인증 요청 버튼 클릭',
    type: 'click',
  },
  confirm_continue_writing: {
    description: '매물 등록 > 작성 중인 글 이어 쓰기 알럿 > 확인 클릭',
    type: 'click',
  },
};
