/**
 * @generated SignedSource<<b4645a884ca6cc19123014836a3a1155>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type SalesTypeEnum = "APART" | "ETC" | "OFFICETEL" | "OPEN_ONE_ROOM" | "SPLIT_ONE_ROOM" | "STORE" | "TWO_ROOM";
import { FragmentRefs } from "relay-runtime";
export type articleSalesTypeText_article$data = {
  readonly salesTypeV2: {
    readonly etcType?: string;
    readonly type: SalesTypeEnum;
  };
  readonly " $fragmentType": "articleSalesTypeText_article";
};
export type articleSalesTypeText_article$key = {
  readonly " $data"?: articleSalesTypeText_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"articleSalesTypeText_article">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "articleSalesTypeText_article"
};

(node as any).hash = "273a4da68f8ede96c234c2497997e434";

export default node;
