import { z } from 'zod';
import { isNil } from 'lodash-es';
import {
  ArticleBuildingOrientationEnum,
  ArticleManageCostOptionEnum,
  ArticleOptionNameEnum,
  ArticleOptionValueEnum,
  ArticleQualitativeItemEnum,
  BuildingUsageEnum,
  ManageCostPayOptionEnum,
  SalesTypeEnum,
  TradeTypeEnum,
} from '@/types/schemaEnums';
import {
  MAX_IMAGE_COUNT,
  MAX_QUALITATIVE_ITEM_COUNT,
  MIN_IMAGE_COUNT,
  MonthlyPayableTypes,
  RequiredArticleOptionNames,
} from '@/constants/article';

export const MAX_ADDRESS_INFO_LENGTH = 30;
export const MAX_TRADE_DESCRIPTION_LENGTH = 50;

z.setErrorMap((issue) => {
  switch (issue.code) {
    case 'invalid_type': {
      if (issue.received === 'undefined') {
        return {
          message: '필수 항목이에요',
        };
      }
      return {
        message: '잘못된 형식이에요',
      };
    }
    case 'too_big': {
      return {
        message: `최대 ${issue.maximum}까지 입력할 수 있어요.`,
      };
    }
    case 'too_small': {
      return {
        message:
          issue.type === 'string' ? '필수 항목이에요' : `최소 ${issue.minimum}개는 입력해야 해요.`,
      };
    }
  }

  return {
    message: '잘못된 형식이에요.',
  };
});

// Image, Video, and Broker types
const Image = z.object({
  id: z.string(),
  thumbnail: z.string(),
  file: z.string().optional(),
  url: z.string().optional(),
  medium: z.string().optional(),
  width: z.number().optional(),
  height: z.number().optional(),
});

export type ImageType = z.infer<typeof Image>;

const Video = z.object({
  id: z.string(),
  filename: z.string(),
  bigStreamId: z.string(),
});

export type VideoType = z.infer<typeof Video>;

// Coordinate input type
const CoordinateInput = z.object({
  lat: z.string(),
  lon: z.string(),
});

// Additional input types
const ArticleManageCostOptionDetailInput = z.object({
  fixedCost: z.number().int('관리비 고정금액 소수점은 제외해주세요.').optional().nullable(),
  option: z.nativeEnum(ArticleManageCostOptionEnum),
  payOption: z.nativeEnum(ManageCostPayOptionEnum).optional().nullable(),
});

const ArticleOptionInput = z.object({
  name: z.nativeEnum(ArticleOptionNameEnum),
  value: z.nativeEnum(ArticleOptionValueEnum),
});

const ArticleTradeInputV2 = z
  .object({
    adjustable: z.boolean().optional().nullable(),
    description: z.string().max(255, '가격 설명은 255자 이내로 입력해주세요.').nullish(),
    monthlyPay: z.number().int('가격 소수점은 제외해주세요.').nullish(),
    price: z.number().int('가격 소수점은 제외해주세요.').optional(),
    tradeType: z.nativeEnum(TradeTypeEnum),
  })
  .superRefine((trade, ctx) => {
    if (!trade.price) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['price'],
        message: '가격/보증금을 입력해주세요.',
      });
    }

    if (trade.tradeType && MonthlyPayableTypes.includes(trade.tradeType) && !trade.monthlyPay) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['monthlyPay'],
        message: '월세를 입력해주세요.',
      });
    }
  })
  .transform((trade) => ({
    ...trade,
    price: trade.price ?? 0,
  }));

// https://github.com/colinhacks/zod/issues/2524
// refine은 해당 스킴에 대한 검증이 완료 된 후 이루어지기 때문에 스킴을 하나로 가져가면 다른 validation 룰이 실패할 경우 실행이 안됨.
// 따라서 쪼개줌
const RequiredOptionsScheme = z
  .object({
    requiredOptions: z.array(ArticleOptionInput),
    availableParkingSpotsV2: z.number().nullish(),
  })
  .superRefine(({ requiredOptions, availableParkingSpotsV2 }, ctx) => {
    if (
      RequiredArticleOptionNames.some(
        (requiredOption) => !requiredOptions?.find((option) => option.name === requiredOption)
      )
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['requiredOptions'],
        message: '대출, 애완동물, 주차 옵션은 필수에요.',
      });
    }

    if (
      requiredOptions?.some((option) => option.name === 'PARKING' && option.value === 'YES') &&
      isNil(availableParkingSpotsV2)
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['availableParkingSpotsV2'],
        message: '세대당 가능한 주차대수를 알려주세요.',
      });
    }
  });

const ManageCostScheme = z
  .object({
    excludeManageCostOption: z.array(z.nativeEnum(ArticleManageCostOptionEnum)).nullish(),
    etcManageCost: z.number().nullish(),
    includeManageCostOption: z.array(ArticleManageCostOptionDetailInput).nullish(),
    isUnknownManageCost: z.boolean().nullish(),
    manageCost: z.number().nullish(),
  })
  .superRefine(({ isUnknownManageCost, includeManageCostOption, manageCost }, ctx) => {
    if (!isUnknownManageCost) {
      if (isNil(manageCost)) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_type,
          expected: 'number',
          received: 'undefined',
          path: ['manageCost'],
        });
      }

      if (
        includeManageCostOption?.length === 0 ||
        Object.values(ArticleManageCostOptionEnum).some(
          (option) => !includeManageCostOption?.find((o) => o.option === option)
        )
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['includeManageCostOption'],
          message: '관리비 항목을 모두 설정해주세요.',
        });
      }

      Object.values(ArticleManageCostOptionEnum).forEach((option) => {
        const manageCostOption = includeManageCostOption?.find((o) => o.option === option);

        if (!manageCostOption) {
          return;
        }

        if (
          manageCostOption.payOption === ManageCostPayOptionEnum.Fixed &&
          isNil(manageCostOption.fixedCost)
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['includeManageCostOption', option, 'fixedCost'],
            message: '관리비 항목의 고정비용을 입력해주세요.',
          });
          return;
        }

        if (
          manageCostOption.payOption === ManageCostPayOptionEnum.Fixed &&
          !Number.isInteger(manageCostOption.fixedCost)
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['includeManageCostOption', option, 'fixedCost'],
            message: '관리비 고정금액 소수점을 제외해주세요.',
          });
        }
      });
    }
  });

export const articleFormScheme = z.intersection(
  z.intersection(
    z.object({
      address: z.string().min(1),
      addressInfo: z.string().optional().nullable(),
      area: z
        .string()
        .min(1)
        .superRefine((area, ctx) => {
          if (isNaN(Number(area))) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['area'],
              message: '잘못된 형식이에요.',
            });
          }
        }),
      supplyArea: z
        .string()
        .min(1)
        .superRefine((area, ctx) => {
          if (isNaN(Number(area))) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['area'],
              message: '잘못된 형식이에요.',
            });
          }
        }),
      bathroomCnt: z.number().int('화장실 개수 소수점은 제외해주세요.'),
      buildingOrientation: z.nativeEnum(ArticleBuildingOrientationEnum),
      buildingUsage: z.nativeEnum(BuildingUsageEnum),
      content: z.string().optional().default(''),
      coordinate: CoordinateInput,
      corRealtyId: z.string().optional().nullable(),
      etcSalesType: z.string().optional().nullable(),
      floor: z.string().optional().nullable(),
      fullAddress: z.string().optional().nullable(),
      images: z.array(Image).min(MIN_IMAGE_COUNT).max(MAX_IMAGE_COUNT),
      moveInDate: z.string().date(),
      videos: z.array(Video).optional(),
      premiumMoney: z.number().int('권리금 소수점은 제외해주세요.').optional().nullable(),
      premiumMoneyDescription: z.string().optional().nullable(),
      qualitativeItems: z
        .array(z.nativeEnum(ArticleQualitativeItemEnum))
        .max(MAX_QUALITATIVE_ITEM_COUNT)
        .optional(),
      roomCnt: z.number().int('방 수 소수점은 제외해주세요.'), // Required
      salesType: z.nativeEnum(SalesTypeEnum),
      topFloor: z.string().min(1), // Required
      writerCoordinate: CoordinateInput.optional().nullable(),
      trades: z
        .array(ArticleTradeInputV2)
        .nonempty()
        .transform((trades) => trades.map((trade, i) => ({ ...trade, preferred: i === 0 }))),
      options: z.array(ArticleOptionInput).optional().nullable(),
      buildingApprovalDate: z.string().date(),
      isContactTargetEnabled: z.boolean().nullish(),
    }),
    ManageCostScheme
  ),
  RequiredOptionsScheme
);

export type ArticleFormInputType = z.input<typeof articleFormScheme>;
export type ArticleFormType = z.infer<typeof articleFormScheme>;
