import { createFileRoute } from '@tanstack/react-router';
import { Suspense, useEffect, useRef, useState } from 'react';
import { graphql, useMutation } from 'react-relay';
import { newPageMutation } from '@/__generated__/newPageMutation.graphql';
import { getRelayNetworkErrorHandler, handleMutationResponse } from '@/relay/utils';
import { ActionHistoryKey, realtyLocalStorage } from '@/utils/localStorage';
import logger from '@/utils/Logger';
import { ErrorBoundary } from '@sentry/react';
import RealtyHomeBanner from '@/routes/_protected/articles/-components/RealtyHomeBanner';
import BrokerFaqBanner from '@/routes/_protected/articles/-components/BrokerFaqBanner';
import RequestVerifyDialog from '@/routes/_protected/articles/-components/RequestVerifyDialog';
import useAppQuery from '@/hooks/useAppQuery';
import { Button, ProgressCircle } from '@daangn/carotene';
import CheckIros from '@/routes/_protected/articles/-components/CheckIros';
import ArticleForm, {
  type ArticleFormRef,
} from '@/routes/_protected/articles/-components/ArticleForm';
import type { ArticleFormType } from '@/scheme/article';
import ReportBanner from '@/routes/_protected/articles/-components/ReportBanner';
import { formToArticleInput } from '@/utils/article';

export const Route = createFileRoute('/_protected/articles/new')({
  component: Page,
});

function Page() {
  const form = useRef<ArticleFormRef>(null);
  const { viewer } = useAppQuery();
  const [createdArticleId, setCreatedArticleId] = useState<string | null>(null);
  const [showRequestVerifyDialog, setShowRequestVerifyDialog] = useState(false);
  const [showRealtyHomeBanner, setShowRealtyHomeBanner] = useState(
    () => !realtyLocalStorage.getActionHistory(ActionHistoryKey.SeenRealtyHomeBanner)
  );

  const [createArticle, isInFlight] = useMutation<newPageMutation>(graphql`
    mutation newPageMutation($input: CreateArticleInputV3!) {
      createArticleV3(input: $input) {
        __typename
        ... on CreateArticleOutput_Result {
          result {
            article {
              id
              originalId
            }
          }
        }
        ... on ToastError {
          toastError {
            message
          }
        }
      }
    }
  `);

  const handleClickSubmit = async (form: ArticleFormType) => {
    logger.track('create_article', {});

    const input = formToArticleInput(form, {
      isEdit: false,
      viewer,
    });

    createArticle({
      variables: {
        input,
      },
      onCompleted: (data) => {
        handleMutationResponse(data.createArticleV3, {
          onResult: ({ result }) => {
            realtyLocalStorage.clearTempArticle();
            realtyLocalStorage.setActionHistory(ActionHistoryKey.SeenRealtyHomeBanner);

            setCreatedArticleId(result.article.originalId);

            logger.track('complete_submit', {
              input,
            });
          },
          onToastError(toastError) {
            window.alert(toastError.toastError.message);
          },
        });
      },
      onError: getRelayNetworkErrorHandler(),
    });
  };

  const handleClickWriteMore = () => {
    logger.track('click_write_more', {});

    form.current?.reset();
    setShowRealtyHomeBanner(false);
    setCreatedArticleId(null);
  };

  const handleClickRequestVerify = () => {
    logger.track('click_request_verify', {});

    setShowRequestVerifyDialog(true);
  };

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = '';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    form.current?.watch((values) => {
      realtyLocalStorage.setTempArticle(values);
    });
  }, [form.current]);

  useEffect(() => {
    const tempArticle = realtyLocalStorage.getTempArticle();

    if (tempArticle) {
      const confirm = window.confirm('작성 중인 글이 있어요. 글을 이어서 쓸까요?');

      if (confirm) {
        logger.track('confirm_continue_writing', {});
        form.current?.reset(tempArticle);
      } else {
        realtyLocalStorage.clearTempArticle();
      }
    }
  }, []);

  if (createdArticleId) {
    return (
      <div className="flex flex-col gap-6 pt-5 md:pt-[200px] justify-center items-center text-center">
        <img
          style={{ width: 116, height: 120 }}
          src="https://asset-town.krrt.io/production/illust/ccea2e75-8732-4647-b41d-28d2bd5666b1/10b01f333feecd723b9bca674cd544b5e96b8bd4.webp"
        />
        <div className="flex flex-col gap-2">
          <h1 className="heading-large text-2xl">게시글 작성이 완료되었어요</h1>
          <p className="body-medium-default">
            내 게시글은 당근 앱 {'>'} 나의 당근 {'>'} 부동산에서 확인하실 수 있어요.
          </p>
        </div>
        <div className="space-y-3">
          <div>
            <Button size="xlarge" onClick={handleClickWriteMore} style={{ minWidth: 165 }}>
              매물 추가 등록하기
            </Button>
          </div>
          <div>
            <Button
              type="button"
              size="xlarge"
              variant="neutral"
              onClick={handleClickRequestVerify}
              style={{ minWidth: 165 }}
            >
              집주인 인증 요청
            </Button>
          </div>
        </div>
        <RequestVerifyDialog
          isOpen={showRequestVerifyDialog}
          setOpen={setShowRequestVerifyDialog}
          articleId={createdArticleId}
        />
      </div>
    );
  }
  return (
    <div className="relative pt-12 pb-36">
      {isInFlight && (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-full flex items-center justify-center bg-bg-overlay-default z-50">
          <ProgressCircle />
        </div>
      )}
      <div className="flex flex-col gap-10">
        <ErrorBoundary>
          <Suspense>
            <BrokerFaqBanner />
          </Suspense>
        </ErrorBoundary>
        <ArticleForm
          ref={form}
          onSubmit={handleClickSubmit}
          isEdit={false}
          isLoading={isInFlight}
        />
        <div className="mt-24 flex flex-col gap-7">
          {showRealtyHomeBanner && <RealtyHomeBanner />}
          <ReportBanner formRef={form} section="new" />
        </div>
      </div>
      <Suspense fallback={null}>
        <CheckIros />
      </Suspense>
    </div>
  );
}
