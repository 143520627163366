import logger from '@/utils/Logger';
import { Link } from '@tanstack/react-router';
import { Button } from '@daangn/carotene';
import useAppQuery from '@/hooks/useAppQuery';
import { config } from '@/config';
import { IconExpandMoreFill } from '@seed-design/react-icon';

const Header = () => {
  const { viewer } = useAppQuery();

  const handleClickChat = () => {
    logger.track('click_gnb_chat', {});
  };

  return (
    <header className="h-14 fixed top-0 left-0 right-0 bottom-0 border-b border-gray200 bg-bg-layerDefault z-50">
      <div className="flex h-full items-center max-w-screen-md mx-5 md:mx-auto">
        <Link
          to="/articles/new"
          className="[&.active]:font-bold"
          onClick={() => logger.track('header_logo', {})}
        >
          <img src="https://assetstorage.krrt.io/1138369647032855545/d6f6145d-a9ff-4b7f-9384-d8d8511baa6a/width=57,height=32.svg" />
        </Link>

        <div className="spacer" />

        <div className="flex h-full items-center hover:text-fg-neutralSubtle transition-colors">
          <Link
            to="/articles/new"
            className="py-2 px-3"
            onClick={() => logger.track('header_new', {})}
          >
            <button className="body-medium-strong hover:text-fg-neutral">매물 등록</button>
          </Link>

          <div className="relative group">
            <button className="flex items-center gap-[4px] py-2 pl-3 pr-2 group-hover:text-fg-neutral">
              <div className="body-medium-strong hover:text-fg-neutral">매물 관리</div>
              <IconExpandMoreFill size={12} className="text-fg-neutralSubtle" />
            </button>

            <Dropdown />
          </div>
        </div>

        <div className="spacer" />

        {!!viewer && (
          <a href={config.chatUrl} onClick={handleClickChat} target="_blank">
            <Button variant="brandWeak" size="medium">
              PC에서 채팅하기
            </Button>
          </a>
        )}
      </div>
    </header>
  );
};

export default Header;

const SALES_MENU = [
  { label: '나의 매물', path: '/sales/my', name: 'header_sales_my' as const },
  { label: '중개소 매물', path: '/sales/biz', name: 'header_sales_biz' as const },
];
const Dropdown = () => (
  <div
    className="absolute left-0 w-[139px] invisible group-hover:animate-enter-down rounded-[6px] bg-bg-layerElevated py-1.5 px-1 backdrop-blur-[15px] z-10"
    style={{ boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.10)' }}
  >
    {SALES_MENU.map(({ label, path, name }) => (
      <Link to={path} key={path} onClick={() => logger.track(name, {})}>
        <button className="w-full text-start p-2 rounded-1 body-small-default text-fg-neutral hover:bg-bg-layerElevatedHover">
          {label}
        </button>
      </Link>
    ))}
  </div>
);
