import classNames from 'classnames';
import { Toaster, resolveValue, type ToasterProps } from 'react-hot-toast';

type Props = Omit<ToasterProps, 'children'>;

const DefaultToaster = ({ ...props }: Props) => {
  return (
    <Toaster
      position="bottom-center"
      {...props}
      containerStyle={{
        ...props.containerStyle,
        bottom: 116,
      }}
      toastOptions={{
        ...props.toastOptions,
        duration: 3000,
      }}
    >
      {(t) => (
        <div
          className={classNames(
            'py-4 px-5 bg-bg-neutralInverted text-fg-neutralInverted opacity-95 rounded-[10px] w-[344px] [&>div]:m-0',

            {
              'animate-enter': t.visible,
              'animate-leave': !t.visible,
            }
          )}
        >
          {resolveValue(t.message, t)}
        </div>
      )}
    </Toaster>
  );
};

export default DefaultToaster;
