import { MyHistoryArticles_ViewerWriteArticles$key } from '@/__generated__/MyHistoryArticles_ViewerWriteArticles.graphql';
import { MyHistoryArticlesQuery } from '@/__generated__/MyHistoryArticlesQuery.graphql';
import ArticleItem from '@/components/Article/ArticleItem';
import InfiniteLoading from '@/components/InfinteLoading';
import { ArticleStatusEnum, ViewWriteArticleStatusInputEnum } from '@/types/schemaEnums';
import { Button } from '@daangn/carotene';
import { useNavigate } from '@tanstack/react-router';
import { graphql, useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import CarrotQRDialog from '@/components/Dialog/CarrotQRDialog';
import { useOverlay } from '@toss/use-overlay';
import logger from '@/utils/Logger';
import ArticleEmpty from '@/components/Article/ArticleEmpty';

type MyHistoryArticlesProps = {
  selectedStatus: `${ViewWriteArticleStatusInputEnum}`;
};
const MyHistoryArticles = ({ selectedStatus }: MyHistoryArticlesProps) => {
  const queryRef = useLazyLoadQuery<MyHistoryArticlesQuery>(
    graphql`
      query MyHistoryArticlesQuery(
        $first: Int = 20
        $after: String
        $input: ViewerWriteArticlesInput
      ) {
        ...MyHistoryArticles_ViewerWriteArticles
          @arguments(first: $first, after: $after, input: $input)
      }
    `,
    { input: { status: selectedStatus } },
    { fetchPolicy: 'store-and-network' }
  );

  const { data, hasNext, loadNext } = usePaginationFragment<
    MyHistoryArticlesQuery,
    MyHistoryArticles_ViewerWriteArticles$key
  >(
    graphql`
      fragment MyHistoryArticles_ViewerWriteArticles on Query
      @argumentDefinitions(
        first: { type: Int, defaultValue: 20 }
        after: { type: String }
        input: { type: "ViewerWriteArticlesInput" }
      )
      @refetchable(queryName: "ViewerWriteArticlesRefetchQuery") {
        viewerWriteArticles(first: $first, after: $after, input: $input)
          @connection(key: "ViewerWriteArticles_viewerWriteArticles") {
          edges {
            node {
              id
              originalId
              status
              ...ArticleItem_article
            }
            cursor
          }
        }
      }
    `,
    queryRef
  );

  const { edges } = data.viewerWriteArticles;
  const articles = edges.map((v) => v.node);

  if (articles.length < 1) {
    return <ArticleEmpty selectedStatus={selectedStatus} />;
  }

  return (
    <div className="flex flex-col gap-8">
      <div className="w-full grid grid-cols-2 gap-8">
        {articles.map((article, index) => (
          <ArticleItem
            key={article.id}
            articleRef={article}
            index={index}
            showRealtyId
            action={
              <MyHistoryArticleItemAction articleId={article.originalId} status={article.status} />
            }
          />
        ))}
      </div>

      <InfiniteLoading hasNext={hasNext} onLoadMore={() => loadNext(20)} />
    </div>
  );
};
export default MyHistoryArticles;

const MyHistoryArticleItemAction = ({
  articleId,
  status,
}: {
  articleId: string;
  status: `${ArticleStatusEnum}`;
}) => {
  const navigate = useNavigate();

  const { open } = useOverlay();

  const handleTrade = () => {
    logger.track('click_article_action_trade', { article_id: articleId });

    open(({ isOpen, close }) => (
      <CarrotQRDialog articleId={articleId} isOpen={isOpen} close={close} />
    ));
  };

  const handleModify = () => {
    logger.track('click_article_action_modify', { article_id: articleId });

    navigate({
      to: '/articles/edit/$articleId',
      params: {
        articleId: articleId,
      },
    });
  };

  return (
    <>
      <div className="w-full flex gap-2 mt-4">
        <Button variant="neutral" size="large" className="flex-1" onClick={handleTrade}>
          {status === 'TRADED' ? '판매 중' : '거래 완료'}
        </Button>
        <Button variant="neutral" size="large" className="flex-1" onClick={handleModify}>
          수정하기
        </Button>
      </div>
    </>
  );
};
