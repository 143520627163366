import { PropsWithChildren } from 'react';

const Skeleton = {
  Box: ({
    width = '10rem',
    height = 'auto',
  }: {
    width?: string | number;
    height?: string | number;
  }) => {
    const w = typeof width === 'number' ? `w-[${width}px]` : `w-${width}`;
    const h = typeof height === 'number' ? `h-[${height}px]` : `h-${height}`;

    return <div className={`${w} ${h} bg-gray300 animate-pulse rounded`} />;
  },

  Text: ({ children, className = '' }: PropsWithChildren<{ className?: string }>) => (
    <div className={`w-fit bg-gray300 animate-pulse text-transparent ${className} rounded `}>
      {children || <span>로딩 중</span>}
    </div>
  ),
};

export default Skeleton;
