import Field from '@/routes/_protected/articles/-components/Field';
import { useArticleForm } from '@/routes/_protected/articles/-contexts';
import { SalesTypeEnum } from '@/types/schemaEnums';
import { useController } from 'react-hook-form';
import { getArticleSalesTypeText } from '@daangn/realty-sdk';
import FieldLogger from '@/routes/_protected/articles/-components/FieldLogger';
import { Radio, RadioGroup, TextField } from '@daangn/carotene';

const SalesTypes = [
  SalesTypeEnum.OpenOneRoom,
  SalesTypeEnum.SplitOneRoom,
  SalesTypeEnum.TwoRoom,
  SalesTypeEnum.Apart,
  SalesTypeEnum.Officetel,
];

const SalesTypeField = () => {
  const { control } = useArticleForm();
  const salesType = useController({ control, name: 'salesType' });
  const etcSalesType = useController({ control, name: 'etcSalesType' });

  return (
    <Field label="매물 종류" {...salesType.fieldState}>
      <RadioGroup
        {...salesType.field}
        value={salesType.field.value ?? undefined}
        onValueChange={salesType.field.onChange}
      >
        <div className="grid grid-cols-6 gap-6 w-full">
          {SalesTypes.map((type) => (
            <FieldLogger key={type} name="sales_type" eventParams={{ type }}>
              <Radio value={type} label={getArticleSalesTypeText(type, { subText: true })} />
            </FieldLogger>
          ))}
        </div>
      </RadioGroup>
      {salesType.field.value === SalesTypeEnum.Etc && (
        <TextField
          {...etcSalesType}
          invalid={!!etcSalesType.fieldState.error}
          errorMessage={etcSalesType.fieldState.error?.message}
          label="기타"
        />
      )}
    </Field>
  );
};

export default SalesTypeField;
