import { SalesHistoryStatus } from '@/routes/_protected/sales/-components/ChipList';

const Label: Record<SalesHistoryStatus, string> = {
  TRADED: '거래 완료된',
  ON_SALES: '판매 중인',
  HIDDEN: '숨긴',
};
const ArticleEmpty = ({ selectedStatus }: { selectedStatus: SalesHistoryStatus }) => {
  return (
    <div className="h-full w-full text-center p-32 text-gray600">
      <div>{Label[selectedStatus]} 게시글이 없어요.</div>
    </div>
  );
};

export default ArticleEmpty;
