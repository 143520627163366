import { mySalesHistoryListQuery } from '@/__generated__/mySalesHistoryListQuery.graphql';
import MainEnvironment from '@/relay/environment';
import { createFileRoute, useLoaderData } from '@tanstack/react-router';
import { Suspense, useState } from 'react';
import { loadQuery, PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay';
import { graphql } from 'relay-runtime';
import ChipList, { SalesHistoryStatus } from './-components/ChipList';
import { useRefetchQuery } from '@/hooks/useRefetchQuery';
import MyHistoryArticles from './-components/MyHistoryArticles';
import ArticleListSkeleton from '@/components/Skeleton/ArticleListSkeleton';
import ReportBanner from '../articles/-components/ReportBanner';

export const Route = createFileRoute('/_protected/sales/my')({
  loader: async ({}) => {
    return loadQuery<mySalesHistoryListQuery>(MainEnvironment, MySalesHistoryListQuery, {});
  },
  component: Page,
});

const MySalesHistoryListQuery = graphql`
  query mySalesHistoryListQuery {
    viewerWriteArticleStatusGroupByCount {
      count
      status
    }
  }
`;

function Page() {
  const appQueryRef = useLoaderData({ from: Route.id });
  const [queryRef, loadQuery] = useQueryLoader<mySalesHistoryListQuery>(
    MySalesHistoryListQuery,
    appQueryRef
  );

  const { refresh } = useRefetchQuery({
    query: MySalesHistoryListQuery,
    queryRef: appQueryRef,
    loadQuery,
  });

  if (!queryRef) throw new Error('게시글 정보를 불러오지 못했어요.');

  return <PageContent queryRef={queryRef} refresh={refresh} />;
}

type PageContentProps = {
  queryRef: PreloadedQuery<mySalesHistoryListQuery>;
  refresh: () => void;
};
const PageContent = ({ refresh, queryRef }: PageContentProps) => {
  const { viewerWriteArticleStatusGroupByCount } = usePreloadedQuery(
    MySalesHistoryListQuery,
    queryRef
  );

  const [selectedStatus, setSelectedStatus] = useState<SalesHistoryStatus>('ON_SALES');
  const handleChangeTab = (status: SalesHistoryStatus) => {
    setSelectedStatus(status);
    refresh();
  };

  return (
    <div className="pt-12 pb-36 flex flex-col gap-12">
      <div>
        <div className="heading-large mb-4">나의 매물</div>

        {/* Chip */}
        <ChipList
          selectedStatus={selectedStatus}
          statusGroupByCount={viewerWriteArticleStatusGroupByCount}
          onSelectedStatusChange={handleChangeTab}
        />
      </div>

      {/* Article */}
      <Suspense fallback={<ArticleListSkeleton />}>
        <MyHistoryArticles selectedStatus={selectedStatus} />
      </Suspense>

      <ReportBanner section="sales_my" />
    </div>
  );
};
