import { LazyLoadImage, LazyLoadImageProps } from 'react-lazy-load-image-component';

const EMPTY_IMAGE =
  'https://assetstorage.krrt.io/1082534123824870175/b29f0966-b161-4615-a591-79f739f75d5d/width=840,height=840.webp';

interface Props extends Omit<LazyLoadImageProps, 'visibleByDefault'> {
  lazy?: boolean;
  fallback?: string;
}

export const Image = ({
  lazy = false,
  src,
  alt = '',
  fallback = EMPTY_IMAGE,
  className,
  ...props
}: Props) => {
  const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    if (fallback && e.currentTarget.src !== fallback) {
      e.currentTarget.src = fallback;
    }
  };

  return (
    <LazyLoadImage
      visibleByDefault={!lazy}
      effect="opacity"
      src={src}
      alt={alt}
      className={className}
      onError={(e) => {
        handleError(e);
        props.onError?.(e);
      }}
      {...props}
    />
  );
};
