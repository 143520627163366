import { useArticleForm } from '@/routes/_protected/articles/-contexts';
import { useController } from 'react-hook-form';
import cx from 'classnames';
import { omit } from 'lodash-es';
import { IconPhoneFill } from '@daangn/react-monochrome-icon';
import { Label } from '@/components/ui/Label';
import useAppQuery from '@/hooks/useAppQuery';
import { Switch } from '@daangn/carotene';

const ContactTargetNumberField = () => {
  const { control } = useArticleForm();
  const isContactTargetEnabled = useController({
    control,
    name: 'isContactTargetEnabled',
  });
  const { viewer } = useAppQuery();
  const telephoneNumber = viewer?.bizProfileBroker?.contactPhoneNumber;

  if (!telephoneNumber) {
    return null;
  }

  return (
    <div className="flex flex-col gap-3">
      <Label
        indicator={
          <span>
            개인정보 보호를 위해 다른 이웃에게는 <span className="text-blue600">안심번호</span>로
            노출돼요.
          </span>
        }
        option={
          <Switch
            {...omit(isContactTargetEnabled.field, ['value', 'onChange'])}
            checked={!!isContactTargetEnabled.field.value}
            onCheckedChange={isContactTargetEnabled.field.onChange}
          />
        }
      >
        전화 문의 받기
      </Label>
      <div
        className={cx('flex gap-2 py-[15px] px-[14px] rounded-md bg-bg-layerDefaultHover', {
          'text-fg-placeholder': !isContactTargetEnabled.field.value,
        })}
      >
        <IconPhoneFill size={24} />
        <span>{telephoneNumber}</span>
      </div>
    </div>
  );
};

export default ContactTargetNumberField;
