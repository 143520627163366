import { bizProfileSalesHistoryListQuery } from '@/__generated__/bizProfileSalesHistoryListQuery.graphql';
import MainEnvironment from '@/relay/environment';
import { createFileRoute, useLoaderData } from '@tanstack/react-router';
import { loadQuery, PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay';
import { graphql } from 'relay-runtime';
import { useRefetchQuery } from '@/hooks/useRefetchQuery';
import { Suspense, useState } from 'react';
import ChipList, { type SalesHistoryStatus } from './-components/ChipList';
import BizHistoryArticles from './-components/BizHistoryArticles';
import ArticleListSkeleton from '@/components/Skeleton/ArticleListSkeleton';
import ReportBanner from '../articles/-components/ReportBanner';

export const Route = createFileRoute('/_protected/sales/biz')({
  loader: async ({}) => {
    return loadQuery<bizProfileSalesHistoryListQuery>(
      MainEnvironment,
      BizProfileSalesHistoryListQuery,
      {}
    );
  },
  component: Page,
});

const BizProfileSalesHistoryListQuery = graphql`
  query bizProfileSalesHistoryListQuery {
    bizProfileArticleStatusGroupByCount {
      count
      status
    }
  }
`;

function Page() {
  const appQueryRef = useLoaderData({ from: Route.id });
  const [queryRef, loadQuery] = useQueryLoader<bizProfileSalesHistoryListQuery>(
    BizProfileSalesHistoryListQuery,
    appQueryRef
  );

  const { refresh } = useRefetchQuery({
    query: BizProfileSalesHistoryListQuery,
    queryRef: appQueryRef,
    loadQuery,
  });

  if (!queryRef) throw new Error('게시글 정보를 불러오지 못했어요.');

  return <PageContent queryRef={queryRef} refresh={refresh} />;
}

type PageContentProps = {
  queryRef: PreloadedQuery<bizProfileSalesHistoryListQuery>;
  refresh: () => void;
};
const PageContent = ({ refresh, queryRef }: PageContentProps) => {
  const { bizProfileArticleStatusGroupByCount } = usePreloadedQuery(
    BizProfileSalesHistoryListQuery,
    queryRef
  );

  const [selectedStatus, setSelectedStatus] = useState<SalesHistoryStatus>('ON_SALES');
  const handleChangeTab = (status: SalesHistoryStatus) => {
    setSelectedStatus(status);
    refresh();
  };

  return (
    <div className="pt-12 pb-36 flex flex-col gap-12">
      <div>
        <div className="heading-large">중개소 매물</div>
        <div className="body-xsmall-default text-fg-neutralMuted mt-1.5 mb-4">
          부동산 홈 우측 삼선 메뉴(≡)의 '나의 매물' 에서 중개소 매물을 관리할 수 있어요.
        </div>

        {/* Chip */}
        <ChipList
          selectedStatus={selectedStatus}
          statusGroupByCount={bizProfileArticleStatusGroupByCount}
          onSelectedStatusChange={handleChangeTab}
        />
      </div>

      {/* Article */}
      <Suspense fallback={<ArticleListSkeleton />}>
        <BizHistoryArticles selectedStatus={selectedStatus} />
      </Suspense>

      <ReportBanner section="sales_biz" />
    </div>
  );
};
