/**
 * @generated SignedSource<<79cb322619b4f3a9e8f244ef4990dbf1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BizProfileArticleStatusInputEnum = "HIDDEN" | "ON_SALES" | "TRADED";
export type bizProfileSalesHistoryListQuery$variables = Record<PropertyKey, never>;
export type bizProfileSalesHistoryListQuery$data = {
  readonly bizProfileArticleStatusGroupByCount: ReadonlyArray<{
    readonly count: number;
    readonly status: BizProfileArticleStatusInputEnum;
  }>;
};
export type bizProfileSalesHistoryListQuery = {
  response: bizProfileSalesHistoryListQuery$data;
  variables: bizProfileSalesHistoryListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "BizProfileArticleStatusGroupByCount",
    "kind": "LinkedField",
    "name": "bizProfileArticleStatusGroupByCount",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "count",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "bizProfileSalesHistoryListQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "bizProfileSalesHistoryListQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "d5a3511da6a319c74f7671ca3f347fd8",
    "id": null,
    "metadata": {},
    "name": "bizProfileSalesHistoryListQuery",
    "operationKind": "query",
    "text": "query bizProfileSalesHistoryListQuery {\n  bizProfileArticleStatusGroupByCount {\n    count\n    status\n  }\n}\n"
  }
};
})();

(node as any).hash = "b1013eef0236c2e53d3cd07ccc8f99d0";

export default node;
