import {
  __publicField
} from "./chunk-QZ7TP4HQ.mjs";

// src/apis/realtyClient/index.ts
var BASE_URL = {
  traded: "https://realty-traded.karrot.workers.dev",
  realty: "https://realty.krrt.io"
};
var RealtyClientAPI = class {
  async fetch(base, path, options) {
    const response = await fetch(`${BASE_URL[base]}${path}`, {
      ...options,
      headers: {
        "Content-Type": "application/json",
        ...options == null ? void 0 : options.headers
      }
    });
    if (!response.ok) {
      throw new Error(`API request failed: ${response.status} ${response.statusText}`);
    }
    return response.json();
  }
  async getBuildingLedgerTitle(address) {
    const searchParams = new URLSearchParams({ address });
    return this.fetch(
      "traded",
      `/building-ledger/title?${searchParams.toString()}`
    );
  }
  async getFacilities(params) {
    const searchParams = new URLSearchParams({
      lat: params.coordinate.lat.toString(),
      lon: params.coordinate.lon.toString(),
      minutes: params.minutes.toString()
    });
    return this.fetch("traded", `/facility?${searchParams.toString()}`);
  }
  async postShortenUrl(longUrl) {
    return this.fetch("realty", `/shorten/new`, {
      method: "POST",
      body: JSON.stringify({ longUrl })
    });
  }
};

// src/apis/kakao/index.ts
var KakaoAPI = class {
  constructor(apiKey) {
    __publicField(this, "apiKey");
    this.apiKey = apiKey;
  }
  formatArrayParams(params) {
    const searchParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => {
      if (Array.isArray(value)) searchParams.set(key, value.join(","));
      else searchParams.set(key, String(value));
    });
    return searchParams.toString();
  }
  async fetchAPI(path, options) {
    if (!this.apiKey) {
      throw new Error("Kakao API key is not set");
    }
    const response = await fetch(`https://dapi.kakao.com${path}`, {
      ...options,
      headers: {
        ...options == null ? void 0 : options.headers,
        Authorization: `KakaoAK ${this.apiKey}`
      }
    });
    return response.json();
  }
  async searchAddress({
    query,
    page = 1,
    size = 1,
    analyze_type = "exact"
  }) {
    const result = await this.fetchAPI(
      `/v2/local/search/address.json?${this.formatArrayParams({
        page,
        size,
        analyze_type,
        query
      })}`
    );
    return result;
  }
  async getCoordInfo(keyword) {
    let coordInfo = [];
    const data = await this.searchAddress({
      query: keyword,
      page: 1,
      size: 1,
      analyze_type: "exact"
    });
    if (data.documents[0]) coordInfo = [data.documents[0].x, data.documents[0].y];
    return coordInfo;
  }
  async searchByKeyword(request) {
    const params = {
      page: 1,
      ...request
    };
    const data = await this.fetchAPI(
      `/v2/local/search/keyword.json?${this.formatArrayParams(params)}`
    );
    return data;
  }
  async searchByCategory(request) {
    const data = await this.fetchAPI(
      `/v2/local/search/category.json?${this.formatArrayParams(request)}`
    );
    return data;
  }
};

export {
  RealtyClientAPI,
  KakaoAPI
};
