import { PropsWithChildren, type ReactNode } from 'react';
import cx from 'classnames';
import { FieldError } from 'react-hook-form';
import { IconExclamationmarkCircleFill } from '@daangn/react-monochrome-icon';
import { Label } from '@/components/ui/Label';

type Props = PropsWithChildren<{
  label?: string;
  description?: string;
  indicator?: string;
  option?: ReactNode;
  invalid?: boolean;
  error?: FieldError;
}>;

const Field = ({ label, indicator, option, invalid, error, children }: Props) => {
  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-1">
        <Label indicator={indicator} option={option}>
          {label}
        </Label>
        {invalid && error?.message && (
          <span className={cx('body-xsmall-default text-fg-negative flex gap-1 items-center')}>
            <IconExclamationmarkCircleFill width={12} height={12} />
            <span>{error.message}</span>
          </span>
        )}
      </div>
      <div>{children}</div>
    </div>
  );
};

export default Field;
