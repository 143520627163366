/**
 * @generated SignedSource<<bf39b0994a6e6232ff89fb3b9cab17df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type mainQuery$variables = Record<PropertyKey, never>;
export type mainQuery$data = {
  readonly viewer: {
    readonly bizProfileBroker: {
      readonly bizProfile: {
        readonly businessRegistrationNumber: string;
        readonly id: string;
        readonly jibunAddress: string;
        readonly licenseOwnerContact: string;
        readonly licenseOwnerName: string;
        readonly licenseRegistrationNumber: string;
        readonly localProfileId: string;
        readonly name: string;
        readonly originalId: string;
        readonly roadAddress: string;
        readonly thumbImageUrl: string;
      };
      readonly contactPhoneNumber: string | null;
      readonly id: string;
    } | null;
    readonly id: string;
    readonly isDebugUser: boolean;
    readonly originalId: number;
    readonly " $fragmentSpreads": FragmentRefs<"userLoginUser_user">;
  } | null;
};
export type mainQuery = {
  response: mainQuery$data;
  variables: mainQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isDebugUser",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "originalId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "BizProfileBroker",
  "kind": "LinkedField",
  "name": "bizProfileBroker",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contactPhoneNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BizProfile",
      "kind": "LinkedField",
      "name": "bizProfile",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "localProfileId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "businessRegistrationNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "licenseOwnerName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "licenseOwnerContact",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "licenseRegistrationNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jibunAddress",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "roadAddress",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbImageUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "mainQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "KarrotUser",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineDataFragmentSpread",
            "name": "userLoginUser_user",
            "selections": [
              (v0/*: any*/),
              (v2/*: any*/)
            ],
            "args": null,
            "argumentDefinitions": []
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "mainQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "KarrotUser",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fb9351bbae51a745b6a432e70fbd5454",
    "id": null,
    "metadata": {},
    "name": "mainQuery",
    "operationKind": "query",
    "text": "query mainQuery {\n  viewer {\n    id\n    isDebugUser\n    originalId\n    bizProfileBroker {\n      id\n      contactPhoneNumber\n      bizProfile {\n        id\n        originalId\n        localProfileId\n        name\n        businessRegistrationNumber\n        licenseOwnerName\n        licenseOwnerContact\n        licenseRegistrationNumber\n        jibunAddress\n        roadAddress\n        thumbImageUrl\n      }\n    }\n    ...userLoginUser_user\n  }\n}\n\nfragment userLoginUser_user on KarrotUser {\n  id\n  originalId\n}\n"
  }
};
})();

(node as any).hash = "7815f2768adf45ff4dc1e50911c40513";

export default node;
