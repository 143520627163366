/**
 * @generated SignedSource<<d202ba6ba4bf0c7719fefce1c0a9a0d3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BrokerFaqBannerQuery$variables = Record<PropertyKey, never>;
export type BrokerFaqBannerQuery$data = {
  readonly currentBrokerNotice: {
    readonly content: string;
    readonly originalId: string;
    readonly targetUri: string;
  } | null;
};
export type BrokerFaqBannerQuery = {
  response: BrokerFaqBannerQuery$data;
  variables: BrokerFaqBannerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "originalId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "targetUri",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BrokerFaqBannerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BrokerNotice",
        "kind": "LinkedField",
        "name": "currentBrokerNotice",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "BrokerFaqBannerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BrokerNotice",
        "kind": "LinkedField",
        "name": "currentBrokerNotice",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4042a5dc5c88562b0e8ac386d46de18b",
    "id": null,
    "metadata": {},
    "name": "BrokerFaqBannerQuery",
    "operationKind": "query",
    "text": "query BrokerFaqBannerQuery {\n  currentBrokerNotice {\n    originalId\n    content\n    targetUri\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e7dc2ef4a78b0c917f7e9687ade8fe84";

export default node;
