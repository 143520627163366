import { useCallback, useState } from 'react';
import {
  fetchQuery,
  GraphQLTaggedNode,
  LoadQueryOptions,
  PreloadedQuery,
  useRelayEnvironment,
} from 'react-relay';

type UseRefetchOptions<TVariables> = {
  query: GraphQLTaggedNode;
  queryRef: PreloadedQuery<any>;
  loadQuery: (variables: TVariables, options?: LoadQueryOptions) => void;
};

// https://relay.dev/docs/v13.0.0/guided-tour/refetching/refreshing-queries/#if-you-need-to-avoid-suspense
export const useRefetchQuery = <TVariables>({
  query,
  queryRef,
  loadQuery,
}: UseRefetchOptions<TVariables>) => {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const environment = useRelayEnvironment();

  const refresh = useCallback(() => {
    if (isRefreshing) {
      return;
    }

    const { variables } = queryRef;
    setIsRefreshing(true);

    fetchQuery(environment, query, variables).subscribe({
      complete: () => {
        setIsRefreshing(false);
        loadQuery(variables, { fetchPolicy: 'store-only' });
      },
      error: () => {
        setIsRefreshing(false);
      },
    });
  }, [isRefreshing, queryRef, environment, query, loadQuery]);

  return { isRefreshing, refresh };
};
