import emojiRegex from 'emoji-regex-xs';
import { toast } from 'react-hot-toast';

export const replaceEmoji = (text: string) => {
  const filteredValue = text.replace(emojiRegex(), '');
  if (text !== filteredValue) {
    toast('이모티콘은 입력할 수 없어요.');
  }

  return filteredValue;
};
